<template>
  <div>
    <v-dialog
      v-model="detailDialog"
      persistent
      max-width="400px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-text class="mt-5">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="mb-3"
            >
              <b>{{ $t('Name') }}: </b> {{ data.name }}
            </v-col>

            <v-col
              cols="12"
              class="mb-3"
            >
              <b>{{ $t('Code') }}: </b> {{ data.code }}
            </v-col>

            <v-col
              cols="12"
              class="mb-3"
            >
              <b>{{ $t('Variant') }}: </b> {{ data.variant }}
            </v-col>

            <v-col
              cols="12"
              class="mb-4"
            >
              <b>{{ $t('Quantity') }}: </b> {{ $_format_number(data.quantity) }}m
            </v-col>

            <v-chip
              v-for="(detail, index) in data.details"
              :key="index"
              label
              class="me-2 mb-4"
            >
              {{ detail.top }} x {{ $_format_number(detail.remain_quantity) }}m
            </v-chip>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="$emit('close')"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    detailDialog: { type: Boolean, default: false },
    data: { type: Object, default: () => { } },
  },
}
</script>
<style lang="scss">
</style>